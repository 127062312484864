import Choices from "choices.js";
import { appendDefaultEditor } from "./plugins/pintura.js";

import "./../styles/main.scss";

window.appendDefaultEditor = appendDefaultEditor;

document.addEventListener("DOMContentLoaded", main);

function placesAutocompleteInitialize() {
  if (typeof google === "undefined") {
    return;
  }

  var inputs = document.querySelectorAll(
    ".places-widget input[data-id='map_place']"
  );
  inputs.forEach(function (input) {
    const stype = input.dataset.stype || "(cities)";
    var searchBox = new google.maps.places.Autocomplete(input, {
      types: [stype],
    });
    google.maps.event.addDomListener(input, "keydown", function (e) {
      if (e.keyCode == 13) {
        e.preventDefault();
      }
    });
    searchBox.addListener("place_changed", function () {
      const place = searchBox.getPlace();
      if (place.length == 0) {
        return;
      }
      input.parentElement.querySelector("input[data-id='map_latitude']").value =
        place.geometry.location.lat();
      input.parentElement.querySelector(
        "input[data-id='map_longitude']"
      ).value = place.geometry.location.lng();
    });
  });
}
window.placesAutocompleteInitialize = placesAutocompleteInitialize;

function main() {
  console.log("Load complete");

  function toggleSidenav() {
    const body = document.querySelector("body");
    if (body.classList.contains("offcanvas-visible")) {
      body.classList.add("offcanvas-visible");
      document.getElementById("close-main-nav").click();
    } else {
      body.classList.remove("offcanvas-visible");
      document.getElementById("sidenavToggler").click();
    }

    // document.querySelector("body").classList.toggle("offcanvas-visible");
    // document.getElementById("offcanvas").classList.toggle("show");
  }
  window.toggleSidenav = toggleSidenav;

  /*
  const sideNavToggler = document.querySelector("#sidenavToggler");
  if (sideNavToggler) {
    sideNavToggler.addEventListener("click", function () {
      toggleSidenav();
      this.classList.toggle("is-active");
    });
  }
*/
  //// pagination
  var pageButtons = document.querySelectorAll(
    "button.btn[data-page]:not([data-ajax])"
  );
  pageButtons.forEach(function (input) {
    input.addEventListener("click", function () {
      const form = input.closest("form");
      form.querySelector("input#page").value = input.dataset.page;
      form.submit();
    });
  });

  const shareData = {
    url: window.location.href,
  };

  document.querySelectorAll('[data-choices="multi"]').forEach((el) => {
    let config = {
      searchEnabled: true,
      delimiter: ",",
      editItems: false,
      removeItemButton: true,
    };

    if (el.dataset.async) {
      config.noChoicesText = "Type to search";
    } else {
      config.maxItemCount = el.dataset.max || 4;
    }

    let autocompleter = new Choices(el, config);

    let async_url = el.dataset.async;
    if (async_url) {
      el.addEventListener("search", async (event) => {
        const items = await fetch(async_url + event.detail.value);
        autocompleter.setChoices(await items.json(), "value", "label", true);
      });
    }
  });

  document.querySelectorAll('[data-choices="single"]').forEach((el) => {
    let config = {
      searchEnabled: true,
      delimiter: ",",
      editItems: false,
      removeItemButton: true,
      itemSelectText: "",
    };

    if (el.dataset.async) {
      config.noChoicesText = "Type to search";
    } else {
      config.maxItemCount = 1;
    }

    let autocompleter = new Choices(el, config);

    let async_url = el.dataset.async;
    if (async_url) {
      el.addEventListener("search", async (event) => {
        const items = await fetch(async_url + event.detail.value);
        autocompleter.setChoices(await items.json(), "value", "label", true);
      });
    }
  });

  placesAutocompleteInitialize();

  // back button
  var triggerList = [].slice.call(document.querySelectorAll(".back-button"));
  triggerList.map(function (el) {
    el.addEventListener("click", function (ev) {
      const previousURL = document.referrer;

      const isPreviousURLInternal =
        new URL(previousURL).origin == new URL(location).origin;
      if (isPreviousURLInternal && window.location.href != document.referrer) {
        ev.preventDefault();
        window.location.href = document.referrer;
      }
    });
  });

  // tooltips
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-bs-toggle="tooltip"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });

  // popovers
  var tooltipTriggerList = [].slice.call(
    document.querySelectorAll('[data-toggle="popover"]')
  );
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    let popover = new bootstrap.Popover(tooltipTriggerEl, {
      placement: "top",
      trigger: "click",
    });
    tooltipTriggerEl.addEventListener("click", () => {
      setTimeout(() => {
        popover.hide();
      }, 500);
    });
    return popover;
  });

  const tzone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  if (typeof ctzone !== "undefined" && tzone != ctzone) {
    console.log(tzone);
    let data = new FormData();
    data.append("tz", tzone);
    postContent("/update-tz/", data);
  }
}

function readURL(input) {
  if (input.files && input.files[0]) {
    var reader = new FileReader();

    reader.onload = function (e) {
      if (input.parentNode.querySelector(".image-src")) {
        input.parentNode.querySelector(".image-src").src = e.target.result;
      } else {
        input.parentNode.style.backgroundImage = "url(" + e.target.result + ")";
        input.parentNode
          .querySelector(".image-label")
          .classList.add("image-uploaded");
      }
    };
    reader.readAsDataURL(input.files[0]);
  }
}

const prevLogo = () => {
  frameLogo.src = URL.createObjectURL(event.target.files[0]);
};
const prevHead = () => {
  frameHead.src = URL.createObjectURL(event.target.files[0]);
};

window.readURL = readURL;

function copyEl(src, dst) {
  let clonedEl = src.cloneNode(true);
  dst.appendChild(clonedEl);
}

window.copyEl = copyEl;

function moveEl(src, dst) {
  let el = src;
  dst.appendChild(el);
}

window.moveEl = moveEl;
